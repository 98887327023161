import React, { useState} from "react";
import styled from "styled-components";
import { RiWhatsappFill } from "react-icons/ri";

const ButtonContainer = styled.span`
  position: fixed;
  bottom: 32px;
  right: 32px;
  align-items: center;
  height: 40px;
  ${"" /* width: 60px; */}
  display:flex,
  align-item:center;
  justify-content: center;
  padding: 10px;
  border-radius: 40px 40px 0 40px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  opacity: 0.5;
  background: #c89738;
  color: #000;
  transition: 1s all ease-in-out;
  border-radius: 4px;
  transition: opacity 0.4s, color ease-in-out 0.2s, background ease-in-out 0.2s;
  display: ${({ isScrollButtonVisible }) =>
    isScrollButtonVisible ? "flex" : "none"};

  &:hover {
    opacity: 1;
  }
`;

const BackToTopButton = () => {
  const showButton = useState(true);

  return (
    <ButtonContainer
      isScrollButtonVisible={showButton}
      style={{ borderRadius: "20px 20px 0 20px", width: "maxContent" }}
    >
      <a
        style={{ color: "unset", textDecoration: "none" }}
        href="https://wa.me/+919880761370?text=Hello"
        target="new"
        rel="noreferrer"
      >
        <RiWhatsappFill style={{ fontSize: "large" }} />
        <b style={{ fontSize: "large" }}> Chat</b>
      </a>
    </ButtonContainer>
  );
};

export default BackToTopButton;
