import React from 'react';
import WallDark from "../assets/dark.webp"
import WallLight from "../assets/light.webp"
import { FadeIn } from "react-animated-components";

const PreLoader = (props) => {
  let state = props.state;
  return (
    <section
      id="preLoader"
      style={{
        backgroundColor: state === 0 || state === 1 ? "#363131" : "#363131",
      }}
    >
      <span style={{display:"none"}}>sern barn sereno barn.com sereno barn ,sereno barn.com,sereno serenobarn</span>
      {state === 0 ? (
        <FadeIn iterations="infinite" durationMs="1000" out="true">
          <img
            src={WallDark}
            alt=""
            data-aos="zoom-out"
            className="darkTheme"
          />
        </FadeIn>
      ) : state === 1 ? (
        <img
          src={WallLight}
          alt=""
          data-aos="fade-in"
          className="lightTheme"
        />
      ) : null}
    </section>
  );
}

export default PreLoader;
        // <aside className="goldTheme" data-aos="slide-up">
        //   <img src="" alt="" className="lightTheme" />
        // </aside>
