import React, { lazy, Suspense, useState} from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import BackToTopButton from "./components/BackToTopButton";
import PreLoader from "./components/PreLoader";
const NavBar = lazy(() => import("./components/navbar/NavBar"));
const Home = lazy(() => import("./components/main/Home"));
const AboutUs = lazy(() => import("./components/main/AboutUs"));
const ContactUs = lazy(() => import("./components/main/ContactUs"));
const Dining = lazy(() => import("./components/main/Dining"));
const Dorms = lazy(() => import("./components/main/Dorms"));
const Suits = lazy(() => import("./components/main/Suits"));
const Villa = lazy(() => import("./components/main/Villa"));
const Estate = lazy(() => import("./components/main/Estate"));
const GettingHere = lazy(() => import("./components/main/GettingHere"));
const Gallery = lazy(() => import("./components/main/Gallery/Gallery"));

  AOS.init({
    offset: 200,
    duration: 800,
    easing: "ease-in-out-sine",
    delay: 200,
    mirror: true,
  });

function App() {
  let [state, setState] = useState(0);
  let [time, setTime] = useState(2500);
  let timer = () => {
    if (state < 2) {
      setState(state + 1)
      setTime(time-1000);
    }
    else if (state === 2) {
      setTime(time - 1100);
      setTimeout(()=>{setState(3)}, 1000);
    } else if (state === 3) {
      clearTimeout(timer)
    }
  }
  setTimeout(timer, time);
  return (
    <div className="js-scroll-counter scroll-counter">
      <div className="aos-all">
        {state === 0 || state === 1 ? (
          <PreLoader state={state} />
        ) : (
          <>
            <NavBar />
            <Suspense fallback={<div>Loading...</div>}>
              <Home />
              <AboutUs />
              <Villa />
              <Suits />
              <Dorms />
              <Dining />
              <Estate />
              <Gallery />
              <GettingHere />
              <ContactUs />
              <BackToTopButton />
            </Suspense>
          </>
        )}
      </div>
    </div>
  );
}

export default App;
